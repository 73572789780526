import React, { useState } from 'react'

import DesignBlackAndWhiteImg from './assets/ilus-design.svg'
import DevBlackAndWhiteImg from './assets/ilus-dev.svg'
import GrowthBlackAndWhiteImg from './assets/ilus-growth.svg'

import DesignColorImg from './assets/ilus-design hover.svg'
import DevColorImg from './assets/ilus-dev hover.svg'
import GrowthColorImg from './assets/ilus-growth hover.svg'
import MoreImg from './assets/more.svg'
import CloseImg from './assets/close.svg'

import OnViewTrigger from '../../components/OnViewTrigger/Index'

import {GridContainer, Container, NoWrapDiv, ServiceCard, BlackAndWhiteIllustration, Title, Text, HoverText, SeparatorCircle, ColorIllustration, MoreIcon, Redirect} from './styles';

export const Services : React.FC = () => {

  const [isActiveCard1, setIsActiveCard1] = useState<boolean>(false);
  const [isActiveCard2, setIsActiveCard2] = useState<boolean>(false);
  const [isActiveCard3, setIsActiveCard3] = useState<boolean>(false);

  const [isHiden, setIsHiden] = useState<boolean>(false);
  return (
  
    <Container>
      <GridContainer>
        <OnViewTrigger onView={() => setIsActiveCard1(true)} TriggerPosition={0.3} maxTriggerPosition={0.7} finishOnView={() => setIsActiveCard1(false)}>
        <Redirect to='/work' state={{category: 1}}>
          <ServiceCard isClickedMobile={isActiveCard1}>
              <BlackAndWhiteIllustration className="BlackAndWhiteIllustration" src={DesignBlackAndWhiteImg}/>
              <ColorIllustration className="ColorIllustration" src={DesignColorImg}/>
              <Title className="Title">Design & <br/>Creativity</Title>
              <Text className="Text">UX <SeparatorCircle/> UI <SeparatorCircle/> Product Design <SeparatorCircle/> Mobile First <SeparatorCircle/> Prototyping <SeparatorCircle/> Motion Design <SeparatorCircle/> Brand Identity <SeparatorCircle/> Co-Creation <SeparatorCircle/> Display Art</Text>
              <HoverText className="HoverText">
                Create a strong foundation for your business. Enhance your brand voice and architecture your product positioning. We’ll lead your ideas to the next level.
              </HoverText>
              <MoreIcon src={MoreImg} />
          </ServiceCard>
        </Redirect>
        </OnViewTrigger>
      <OnViewTrigger onView={() => setIsActiveCard2(true)} TriggerPosition={0.3} maxTriggerPosition={0.7} finishOnView={() => setIsActiveCard2(false)}>
        <Redirect to='/work' state={{category: 2}}>  
          <ServiceCard isClickedMobile={isActiveCard2}>
              <BlackAndWhiteIllustration className="BlackAndWhiteIllustration" src={DevBlackAndWhiteImg}/>
              <ColorIllustration className="ColorIllustration"  src={DevColorImg}/>
              <Title className="Title">Software <br/> Development</Title>
              <Text className="Text">Web & Mobile <SeparatorCircle className="SeparatorCircle"/> Cloud Architecture <SeparatorCircle/> Agile Methodologies <SeparatorCircle/> Quality Assurance <SeparatorCircle/> Frontend <SeparatorCircle/> Backend <SeparatorCircle/> CI/CD</Text>
              <HoverText className="HoverText">
                Engineer your brand digital presence with <NoWrapDiv>user-friendly</NoWrapDiv> and reliable software solutions, tailored to empower your business.
              </HoverText>
              <MoreIcon src={MoreImg}/>
          </ServiceCard>
        </Redirect>
      </OnViewTrigger>
      <OnViewTrigger onView={() => setIsActiveCard3(true)} TriggerPosition={0.3} maxTriggerPosition={0.7} finishOnView={() => setIsActiveCard3(false)}>
        <Redirect to='/work' state={{category: 3}}>  
          <ServiceCard isClickedMobile={isActiveCard3}>
            <BlackAndWhiteIllustration className="BlackAndWhiteIllustration" src={GrowthBlackAndWhiteImg}/> 
            <ColorIllustration className="ColorIllustration"  src={GrowthColorImg}/>
            <Title className="Title">Growth & <br/> Marketing</Title>
            <Text className="Text">Social Media <SeparatorCircle/> SEO <SeparatorCircle/> SEM <SeparatorCircle/> Paid User Acquisition <SeparatorCircle/> Social Listening <SeparatorCircle/> Growth Consulting <SeparatorCircle/> Analytics & Insights <SeparatorCircle/> Brand Engagement</Text>
            <HoverText className="HoverText">
              Increase awareness, generate engagement and drive sales to your business with impactful strategies and <NoWrapDiv>high-quality</NoWrapDiv> content campaigns.
            </HoverText>
            <MoreIcon src={MoreImg} />
          </ServiceCard>
        </Redirect>
      </OnViewTrigger>
      </GridContainer>
    </Container>
  )
}
