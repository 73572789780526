import React from 'react'
import { graphql } from "gatsby";

import { useIntl } from "gatsby-plugin-intl"

import SEO from '../components/SEO/SEO'
import { Layout } from '../components/Layout'
import { AnimatedTitle } from '../components/commons/AnimatedTitle'
import { LottiePlayer } from '../components/commons/LottiePlayer'

import { ClientsSwiper } from '../sections/ClientsSwiper'
import { ServicesWheel } from '../sections/ServicesWheel'
import { Services } from '../sections/Services'
import { AwardsCarrousel } from '../sections/Awards'
import { WorksHighlights } from '../sections/WorksHighlights'
import { ContactUs } from '../sections/ContactUs'

import spotRed from "../animations/spot/spot_red.json"

import videoMp4 from "../videos/hero_video_desktop.mp4"
import videoWebm from "../videos/hero_video_desktop.webm"
import mobileVideoMp4 from "../videos/hero_video_mobile.mp4"
import mobileVideoWebm from "../videos/hero_video_mobile.webm"

import seoImage from "../../static/images/seo_image.png"
import videoPosterAvif from "../images/home/background-image.avif"
import videoPosterMobileAvif from "../images/home/back-mobile.avif"
import MouseImg from '../images/home/mouse-icon.svg'

import { Container, BackgroundWrapper } from '../styles/commons'

import {
  HeroVideoContainer,
  VideoGradient,
  Video,
  HeroTextContainer,
  HeroTitle,
  SectionTitle,
  HeroSubtitle,
  MouseContainer,
  MouseImage,
  CarrouselContainer,
} from '../styles/home'
import { PeopleSlider } from '../sections/PeopleSlider';

export const query = graphql`
  query{
    cms {
      achievementsLogos {
        data {
          attributes {
            LogoTitle
            LogoSubtitle
            LogoSubtitleLine2
            LogoImage{
              data {
                attributes {
                  url
                  urlSharp {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH)
                    }
                  }
                }
              }
            }
          }
        }
      }
      works(pagination: {limit: 200}){
        data {
          id
          attributes {
            Categories
            Industry
            Thumbnail_Image {
              data {
                attributes {
                  url
                  urlSharp {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH)
                    }
                  }
                }
              }
            }
            Project_Title
            Subtitle
            Client_Logo {
              data {
                attributes {
                  url
                  urlSharp {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH)
                    }
                  }
                }
              }
            }
            Project_Website
            Priority
            Main_Project_Name
          }
        }
      }
      quotes(pagination: {limit: 200},filters: {type: {eq: "Clients"}}){
        data {
          attributes {
            author
            position
            quote_text
            type
          }
        }
      }
    }
  }
`;

const Home = ({data}:any) => {
  const intl = useIntl();
  return (
  <Layout initialTextColor="white" landing="home">
    <SEO 
      pageTitle={intl.formatMessage({ id: "seoHomeTitle" })}
      description={intl.formatMessage({ id: "seoHomeDescription" })}
      twitterSite="@wearepsh"
      twitterTitle="Providing Digital Transformation | We Are PSh"
      ogUrl="https://wearepsh.com"
      ogDescription="PSh is a digital agency that creates experiences for brands and customers, merging creativity with technology to create innovative and personalized solutions."
      ogImage={"https://wearepsh.com/images/seo_image.png"} 
    />
    <HeroVideoContainer>
        <MouseContainer>
          <MouseImage src={MouseImg} />
        </MouseContainer>

        <VideoGradient />
        <Video poster={videoPosterAvif} autoPlay loop muted playsInline>
          <source src={videoWebm} type="video/webm" />
          <source src={videoMp4} type="video/mp4" />
        </Video>
        <Video mobile poster={videoPosterMobileAvif} autoPlay loop muted playsInline>
          <source src={mobileVideoWebm} type="video/webm" />
          <source src={mobileVideoMp4} type="video/mp4" />
        </Video>

        <HeroTextContainer>
          <Container>
            <AnimatedTitle height="220px" heightMobile="120px" lineDelay={0.5}>
            <HeroTitle id="hero">
              Providing <br/>
              Digital<br/>
              Transformation
            </HeroTitle>
            </AnimatedTitle>
            <HeroSubtitle>
              The Partner your business needs for digital quality solutions
            </HeroSubtitle>
          </Container>
        </HeroTextContainer>
    </HeroVideoContainer>

    <WorksHighlights worksList={data.cms.works.data} />
    <LottiePlayer animation_data={spotRed} loop={true} play={true} />
    <Container marginTop='50px' marginBottom='100px'>
    <AnimatedTitle height="83px" heightMobile="45px" lineDelay={0.3}>
      <SectionTitle id="scrollSection">
        What we do
      </SectionTitle>
    </AnimatedTitle>
      <Services />
    </Container>
        {/* <ServicesWheel /> */}
    <BackgroundWrapper background="#F8F8F8" >
      <Container paddingBottom='80px' paddingTop='80px' paddingTopMobile='30px'>
        <AnimatedTitle height="83px" heightMobile="55px" lineDelay={0.3}>
          <SectionTitle marginMobile={"15px auto auto auto"}>
            Our Clients
          </SectionTitle>
        </AnimatedTitle>
        <ClientsSwiper twoLines/>
      </Container>
    </BackgroundWrapper>

    <CarrouselContainer>
      <Container>
        <AnimatedTitle height="83px" heightMobile="45px" lineDelay={0.3}>
          <SectionTitle fontSizaSmall id="awards">
            Our Achievements
          </SectionTitle>
        </AnimatedTitle>
        <AwardsCarrousel items={data.cms.achievementsLogos.data}/>
      </Container>
    </CarrouselContainer>

    <Container>
    <SectionTitle fontSizaSmall id="awards">
        How people see us
    </SectionTitle>
    <PeopleSlider quotes={data.cms.quotes.data} activeCategory='CLIENTS' innerTitle={false} />
    </Container>
    <a id="contacthome">
      <ContactUs />
    </a>
  </Layout>
)}

export default Home
