import styled, {keyframes} from 'styled-components'
import { DESKTOP_1024, MOBILE_460, TABLET_768 } from '../../../styles/sizes'

const DesktopModule = 150
const MobileModule = 115


// left

const leftOut = (module: number) => keyframes`
  0%{
    transform: none;
  }
  100%{
    transform: translatex(-${module}px);
  }
`

const leftIn = (module: number) => keyframes`
  0%{
    transform: translatex(${module}px);
  }
  100%{
    transform: none;
  }
`


// right

const rightOut = (module: number) => keyframes`
  0%{
    transform: none;
  }
  100%{
    transform: translatex(${module}px);
  }
`

const rightIn = (module: number) => keyframes`
  0%{
    transform: translatex(-${module}px);
  }
  100%{
    transform: none;
  }
`


// top

const topIn = (module: number) => keyframes`
  0%{
    transform: translateY(${module}px);
  }
  100%{
    transform: none;
  }
`

const topOut = (module: number) => keyframes`
  0%{
    transform: none;
  }
  100%{
    transform: translateY(-${module}px);
  }
`

const animations: StringKey = {
  left: {
    in: leftIn,
    out: leftOut,
  },
  right: {
    in: rightIn,
    out: rightOut,
  },
  top: {
    in: topIn,
    out: topOut,
  }
}


export const ItemContainer = styled.div<ItemsProps>`
  width: ${DesktopModule}px;
  height: ${DesktopModule}px;
  overflow: hidden;

  @media (max-width: ${DESKTOP_1024}px) {
    ${(props) => props.removeItem && "display: none"};
  }

  @media (max-width: ${TABLET_768}px) {
    display: block;
  }

  @media (max-width: ${MOBILE_460}px) {
    width: ${MobileModule}px;
    height: ${MobileModule}px;
  }
`

export const Item = styled.div<ItemProps>`
  width: ${DesktopModule}px;
  height: ${DesktopModule}px;
  margin: ${(props) => props.margin || 0};
  margin: ${props => props.startsOut && `-${DesktopModule}px 0`};
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-name: ${(props) => props.animation !== 'sleep' ? (animations[props.animation || 'left'][props.active ? 'out' : 'in'])(DesktopModule) : 'none'};
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: ${MOBILE_460}px) {
    width: ${MobileModule}px;
    height: ${MobileModule}px;
    margin: ${props => props.startsOut && `-${MobileModule}px 0`};
    animation-name: ${(props) => props.animation !== 'sleep' ? (animations[props.animation || 'left'][props.active ? 'out' : 'in'])(MobileModule) : 'none'};
  }
`

export const ItemImage = styled.img`
  width: 100%;
  height: 50%;  
`
interface StringKey { [key: string]: any; }

interface ItemProps {
  animation: string,
  active: boolean,
  margin?: string,
  startsOut?: boolean
}

interface ItemsProps {
  removeItem?: boolean,
}