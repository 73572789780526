import styled, { keyframes } from 'styled-components'
import { TABLET_768 } from '../../styles/sizes'

const fade = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.6;
  }
`

export const Container = styled.div<IWorkProps>`
  width: 100%;
  height: 100%;
  transition: transform 0.5s;
  background: ${props => props.backgroundColor};
  grid-row-start:  ${props => props.rowStart};
  grid-row-end: span 3;
  overflow: hidden;
  position: relative;
  z-index: ${ props => props.hover ? '25' : '22'};
  @media (max-width: ${TABLET_768}px) {
    grid-row-start:  auto;
    grid-row-end: auto;
  }
`
export const Mask = styled.div`
  width: 100%;
  height: 100%;
  background: transparent linear-gradient(180deg, #00000000 0%, #0000009B 63%, #000000BE 100%) 0% 0% no-repeat padding-box;
  position: absolute;
  z-index: 20;
`
export const Picture = styled.picture<IWorkProps>`
  & > img, & > picture {
    width: 100%;
    height: 100%;
    top: 0;
    object-fit: cover;
    left: 0;
    position: absolute;
    transition: transform 0.5s;
    background: ${props => props.backgroundColor};
    transform-origin: bottom left;
    background: red;
    grid-row-start:  ${props => props.rowStart};
    grid-row-end: span 2;
    ${ props => props.hover && 'transition: transform 0.3s; transform: scale(calc(1.1));'}
  }

`
export const Label = styled.div<IWorkProps>`
  width: 90%;
  height: 80px;
  position: absolute;
  display: flex;
  margin-left: 10px;
  align-items: center;
  justify-content: flex-start;
  bottom: 20px;
  z-index: 26;
  @media (max-width: ${TABLET_768}px) {
    height: 75px;
    width: 90%;
  }
`
export const Text = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  align-items: flex-start;
  justify-content: center;
  margin-left: 10px;
`
export const Title = styled.h3`
  display: flex;
  flex-direction: column;
  font-size: 42px;
  color: white;
  margin-bottom: 10px;
  @media (max-width: ${TABLET_768}px) {
    font-size: 30px;
  }
`
export const Description = styled.p`
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 20px;
  color: white;
  text-align: left;
  font-variation-settings: 'wght' 200;
  @media (max-width: ${TABLET_768}px) {
    font-size: 17px;
  }
`
export const Redirect = styled.a``

interface IWorkProps {
  rowStart?: number | string;
  transformOrigin?: string;
  backgroundColor?: string;
  hover?: boolean;
}
