import React from 'react'

import more from '../../animations/more.json';

import { LottiePlayer } from '../commons/LottiePlayer'

import { Picture, Container, Label, Text, Title, Description, Mask, Redirect } from './styles'

export const RedirectCard: React.FC<IProps> = (props: IProps) => {
  return (   
    <Container rowStart={props.rowStart} onMouseLeave={props.onMouseLeave} onMouseEnter={props.onMouseEnter}>
      <Redirect href={props.redirectLink}> 
        <Label hover={props.hover}>
          <LottiePlayer
            play={props.hover}
            animation_height={'70px'}
            animation_width={'70px'}
            animation_data={more as any}
          />
          <Text>
            <Title>{props.title}</Title>
            <Description>{props.description}</Description>
          </Text>
        </Label>
        <Mask />
        <Picture hover={props.hover}>
          <source srcSet={props.avifImage} type="image/avif" />
          <img src={props.image}/>
        </Picture>
      </Redirect>
    </Container>
  )
}

interface IProps {
  rowStart?: string;
  onMouseLeave: any;
  onMouseEnter: any;
  image: any;
  avifImage?: any;
  hover: boolean;
  title: string;
  redirectLink: string;
  description: string;
}
