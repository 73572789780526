import React, { useState, useRef } from 'react'

import { WorksSection, Button, Container, Background, Title, LottieEdit } from './styles'

import { RedirectCard } from '../../components/RedirectCard'

import { LottiePlayer } from '../../components/commons/LottiePlayer'
import { AnimatedTitle } from '../../components/commons/AnimatedTitle'

import redPlus from '../../animations/redPlus.json';

import { IWorksHighlightsProps } from './types';
import { formatProjectTitle } from '../../helpers/WorksHelper'

export const WorksHighlights : React.FC<IWorksHighlightsProps>  = ({ worksList }: IWorksHighlightsProps) => {
  const [hover, setHover] = useState<number>(-1);

  const countRef = useRef(hover);
  countRef.current = hover;

  const handleMouseLeave = (workId: number) => {
    const timer = setTimeout(() => {
      hideLayer();
    }, 300);
    
    const hideLayer = () => {
      if(countRef.current === workId){
        setHover(-1)
      }
    }
  }

  const handleMouseEnter = (workId: number) => {
    setHover(workId)
  }

  const decideRowStart = (index: number) => {
    switch (index){
      case 0: 
        return '2';
      case 1:
        return '1';
      case 2:
        return '5';
      default:
        return '4';
    }
  }


  return (
    <Background>    
      <Container>
        <WorksSection>
        <AnimatedTitle offset='-10%' heightMobile='90px'>
          <Title>See why we are PSh</Title>
        </AnimatedTitle>
        {worksList.sort((a, b) => a.attributes.Priority - b.attributes.Priority).slice(0, 4).map(
          (work, index) => (
            <RedirectCard
              redirectLink={`work/${formatProjectTitle(work.attributes.Project_Title)}`}
              rowStart={decideRowStart(index)}
              image={work.attributes.Thumbnail_Image.data.attributes.url}
              title={work.attributes.Project_Title}
              description={work.attributes.Subtitle}
              onMouseLeave={() => handleMouseLeave(index + 1)}
              onMouseEnter={() => {handleMouseEnter(index + 1)}}
              hover={hover === index + 1}
            />
          )
        )}
        <Button
          onMouseLeave={() => handleMouseLeave(5)}
          onMouseEnter={() => handleMouseEnter(5)}
          href='/work'
        >
          <LottieEdit>
            <LottiePlayer
              play={hover === 5}
              animation_height={'70px'}
              animation_width={'70px'}
              animation_data={redPlus as any}
            />
          </LottieEdit>
          SEE MORE WORK
        </Button>
      </WorksSection>
      </Container>
    </Background>
  )
}
