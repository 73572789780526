import styled from 'styled-components'
import { DESKTOP_1024, TABLET_768 } from '../../styles/sizes'

export const WiperContainer = styled.div`
    width: 100%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto auto;
    justify-items: center;

    @media (max-width: ${ DESKTOP_1024 }px) {
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: auto auto 0px;
        overflow: hidden;
    }
    @media (max-width: ${ TABLET_768 }px) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto auto auto auto;
        margin: 0 auto 0 auto;
    }
`

export const WiperItem = styled.div`
    background: red;
    height: 100px;
    width: 100px;
`
