import styled,{css} from "styled-components";
import { Link } from "gatsby"

import { DESKTOP_1200, MOBILE_500, TABLET_992, TABLET_800, TABLET_600 } from "../../styles/sizes";

export const Container = styled.div`
    width: 100%;
    @media (max-width: ${ TABLET_800 }px ) {
      display: flex;
      justify-content: center;
    }
`

export const GridContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 3%;
    margin-top: 30px;

    @media (max-width: ${ DESKTOP_1200 }px ) {
      grid-column-gap: 2%;
    }

    @media (max-width: ${ TABLET_992 }px ) {
      margin-bottom: 90px;
    }

    @media (max-width: ${ TABLET_800 }px ) {
      grid-template-columns: 1fr;
      margin-top: 0px;
      //margin-bottom: 80px;
      width: 60%;
    }

    @media (max-width: ${ TABLET_600 }px ) {
      width: 70%;
    }

    @media (max-width: ${ MOBILE_500 }px ) {
      width: 100%;
    }
`

export const ServiceCard = styled.div<IdivProps>`
    height: auto;
    display: flex;
    flex-direction: column;
    background-color: #CDD3D765;
    padding: 42px 35px 35px 35px;
    margin-top: 30px;
    transition-duration: 0.5s;
    position: relative;
    align-items: center;

    @media (min-width: ${ TABLET_800 }px ) {
      &:hover{
        margin-top: 0px;
        margin-bottom: 22px;

        .BlackAndWhiteIllustration{
               display: none;
        }
        .ColorIllustration{
            display: block;
        }
        .HoverText{
            opacity: 1;
            height: auto;
            transition-duration: 0.5s;
        }
        .Text{
            display: none;
        }
        .Title{
            transition-duration: 0.5s;
            margin-top: 18px;
        }
    }

    }
    @media (max-width: ${ TABLET_800 }px ) {

      ${props =>
      props.isClickedMobile
        ? css`
              .BlackAndWhiteIllustration{
                    display: none;
              }
              .ColorIllustration{
                  display: block;
              }
              .HoverText{
                  opacity: 1;
                  height: auto;
                  transition-duration: 1s;
              }
              .Text{
                opacity: 0;
                height: 0;
                overflow: hidden;
              }         
          `
        :css`
        .Text{
                  opacity: 1;
                  height: auto;
                  transition-duration: 0.5s;
              }     
        `
      }
    }

`

export const BlackAndWhiteIllustration = styled.img`
    width: 100%;
    max-height: 195px;
    //height:100%;

    @media (max-width: ${ TABLET_800 }px ) {
      max-width: 300px;
      max-height: 191px;
    }
`

export const ColorIllustration = styled.img`
    width: 100%;
    max-height: 195px;
    display: none;

    @media (max-width: ${ TABLET_800 }px ) {
      max-width: 300px;
      max-height: 191px;
    }
`

export const Title = styled.div`
   color: #FD0D1B;
   font: normal normal bold 34px/42px 'Montserrat';
   margin-top: 36px;
   font-variation-settings: 'wght' 500;

   @media (max-width: ${ DESKTOP_1200 }px ) {
    font: normal normal bold 26px/36px 'Montserrat';
   }

   @media (max-width: 830px ) {
    font: normal normal bold 23px/36px 'Montserrat';
    margin-top: 26px;
   }

   @media (max-width: ${ TABLET_800 }px ) {
      font: normal normal bold 34px/42px 'Montserrat';
    }
`

export const Text = styled.div`
   font: normal normal 300 18px/26px "Montserrat";
   color: #0D0D0D;
   margin-top: 26px;

   @media (max-width: 830px ) {
      margin-top: 15px;
   }
`

export const HoverText = styled.div`
   font: normal normal 300 18px/26px "Montserrat";
   color: #0D0D0D;
    transition: opacity 0.5s ease-out;
    opacity: 0;
    height: 0;
    overflow: hidden;
    padding-top: 20px;

    @media (max-width: ${ TABLET_800 }px ) {
      padding-top: 0;
    }
`

export const SeparatorCircle = styled.div`
   border-radius: 50%;
   height: 6px;
   width: 6px;
   margin: 0 5px;
   background-color: #FF0000;
   vertical-align: middle;
   display: inline-block;
`

export const NoWrapDiv = styled.div`
   white-space:nowrap;
   display: inline-block;
`

export const MoreIcon = styled.img<IdivProps>`
  display: none;
  transition: all .3s ease-in-out;
   @media (max-width: ${ TABLET_800 }px ) {
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 73px;
    height: 73px;
   }
`

export const Redirect = styled(Link)`
    text-decoration: none;
    display: flex;
`

interface IdivProps {
  isClickedMobile?: boolean;
  isClicked?: boolean;
}
