import React, { useEffect, useState } from 'react'

import { ItemContainer, Item, ItemImage } from './styles'

export const Slider: React.FC<IProps> = ({States, currentImage, remove}) => {

  const [images, setImages] = useState<any>({
    current: currentImage,
    prev: currentImage,
  });
  const [activeSwitch, setActiveSwitch] = useState(true)

  useEffect(() => {
    setImages({
      current: currentImage,
      prev: images.current,
    })
  }, [currentImage])

  useEffect(() => {
    setActiveSwitch(!activeSwitch)
  }, [images])

  const getImage = (isActive: boolean) => {
    if (isActive) {
      return images.prev
    } 
    return images.current
  }

  return (
    <ItemContainer removeItem={remove}>
      <Item  key={'a'} active={activeSwitch} animation={activeSwitch ? 'top' : 'left'}>
        <ItemImage src={getImage(activeSwitch)} />
      </Item>
      <Item key={'b'} margin={'-200px 0'} startsOut active={!activeSwitch} animation={activeSwitch ? 'left' : 'top'}>
        <ItemImage src={getImage(!activeSwitch)} />
      </Item>
    </ItemContainer>
  )
}

interface IProps {
  States?: any,
  currentImage?: any
  remove?: boolean
}
