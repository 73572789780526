import React, { useEffect, useState } from 'react'

import { Slider } from './Slider';

// new Logos

import bn from "../../images/clients-new/book_nook.svg"
import charthop from "../../images/clients-new/charthop_gris.svg"
import abinbev from "../../images/clients-new/client_logo_abinbev.svg"
import cnt from "../../images/clients-new/client_logo_cnt.svg"
import globant from "../../images/clients-new/client_logo_globant.svg"
import nearpod from "../../images/clients-new/client_logo_nearpod.svg"
import sanofi from "../../images/clients-new/client_logo_sanofi.svg"
import stanford from "../../images/clients-new/client_logo_stanford.svg"
import unilever from "../../images/clients-new/client_logo_unilever.svg"
import cocacola from "../../images/clients-new/COCA_COLA.svg"
import digital_house from "../../images/clients-new/DIGITAL_HOUSE.svg"
import directv from "../../images/clients-new/DIRECTV.svg"
import kiddom from "../../images/clients-new/KIDDOM.svg"
import lantos from "../../images/clients-new/LANTOS.svg"
import marvel from "../../images/clients-new/MARVEL.svg"
import motive from "../../images/clients-new/MOTIVE.svg"
import spartan from "../../images/clients-new/spartan_gris.svg"

import { WiperContainer } from './styles'

const imageList = [
  bn,
  charthop,
  abinbev,
  cnt,
  globant,
  nearpod,
  sanofi,
  stanford,
  unilever,
  cocacola,
  digital_house,
  directv,
  kiddom,
  lantos,
  marvel,
  motive,
  spartan
]

const getRandomInt = (min: any, max: any) => {
  return Math.floor(Math.random() * (max - min)) + min;
}

export const ClientsSwiper: React.FC<IProps> = ({twoLines}) => {

  const [lastChange, setLastChange] = useState<number>()

  const [images, setImages] = useState<any>({
    1: bn,
    2: charthop,
    3: spartan,
    4: nearpod,
    5: kiddom,
    6: unilever,
    7: motive,
    8: marvel,
  });

  useEffect(() => {
    const timer = setTimeout((active) => {
      let newImages = {
        ...images
      };

      let selected = lastChange;

      while (selected == lastChange) {
        selected = getRandomInt(1, (twoLines ? 9 : 6 ));
        if (selected != lastChange) {            
            break;
        } 
    }
      let newImage = imageList[getRandomInt(0, imageList.length)]
      
      while (Object.values(newImages).includes(newImage)) {
       newImage = imageList[getRandomInt(0, imageList.length)]
      }
      
      newImages[selected] = newImage;
      setLastChange(selected);
      setImages(newImages);
    }, 3000);

    timer;
  }, [images])

  return (
    <WiperContainer>
      <Slider currentImage={images[1]}/>
      <Slider currentImage={images[2]}/>
      <Slider currentImage={images[3]}/>
      <Slider remove currentImage={images[4]}/>
      {twoLines &&
      <>
        <Slider currentImage={images[5]}/>
        <Slider currentImage={images[6]}/>
        <Slider currentImage={images[7]}/>
        <Slider currentImage={images[8]}/>
      </>
      }
    </WiperContainer>
  )
}

interface IProps {
  twoLines?: boolean;
}
